import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";
import { BaseService } from "app/shared/Classes/BaseService";

@Injectable({
  providedIn: "root",
})
export class ProductosService extends BaseService {
  constructor(public http: HttpClient, public globals: Globals) {
    super(http, globals);
  }
  public model: string = "producto";
  public route: string = "productos";

  getRegistros(filters?: any) {
    return this.http.get<any[]>(`${this.globals.apiUrl}/${this.route}/getRegistros`, {
      params: { filters: JSON.stringify(filters || {}) },
    });
  }
}
