import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Globals } from './../globals';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http: HttpClient, private globals: Globals, private router: Router) {
  }

  get(){
    return this.http.get(`${this.globals.apiUrl}/config/get`).pipe(catchError(this.handleError));
  }

  update(config) {
    return this.http.post(`${this.globals.apiUrl}/config/update`, {
      config: config
    }).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  };
}
