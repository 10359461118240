import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";

@Injectable({
  providedIn: "root",
})
export class EgresosService {
  constructor(private http: HttpClient, private globals: Globals) {}

  private model: string = "egreso";
  private route: string = "egresos";

  get(filters?: any) {
    return this.http.get<any[]>(`${this.globals.apiUrl}/${this.route}/get`, {
      params: { filters: JSON.stringify(filters || {}) },
    });
  }

  count(filters?: any) {
    return this.http.get<any[]>(`${this.globals.apiUrl}/${this.route}/count`, {
      params: { filters: JSON.stringify(filters || {}) },
    });
  }

  add(data: any) {
    return this.http.post<any>(`${this.globals.apiUrl}/${this.route}/add`, {
      [this.model]: data,
    });
  }

  update(data: any) {
    return this.http.post<any>(`${this.globals.apiUrl}/${this.route}/update`, {
      [this.model]: data,
    });
  }

  delete(id: string | number) {
    return this.http.post<any>(`${this.globals.apiUrl}/${this.route}/delete`, {
      id,
    });
  }
}
